import { pipe, ReplaySubject, first, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from '@angular/core';
import { Injectable, APP_BOOTSTRAP_LISTENER, inject } from '@angular/core';
const createMergeReducer = actionCheck => reducer => (state, action) => {
  let newState = state;
  if (actionCheck(action)) {
    const {
      features
    } = action;
    newState = state ? {
      ...state
    } : {};
    Object.keys(features).forEach(key => {
      newState[key] = {
        ...newState[key],
        ...features[key]
      };
    });
  }
  return reducer(newState, action);
};

/**
 * Exclude keys from the state
 */
const excludeKeys = keys => {
  return pipe(map(state => Object.keys(state).reduce((toSave, key) => {
    if (!keys.includes(key)) {
      toSave[key] = state[key];
    }
    return toSave;
  }, {})));
};

/**
 * Include keys from the state
 */
const includeKeys = keys => {
  return pipe(map(state => Object.keys(state).reduce((toSave, key) => {
    if (keys.includes(key)) {
      toSave[key] = state[key];
    }
    return toSave;
  }, {})));
};

/**
 * Check if states are equal
 */
const isEqual = (prev, next) => {
  if (prev === next) {
    return true;
  }
  if (!prev || !next || typeof prev !== 'object' || typeof next !== 'object') {
    return false;
  }
  const prevSlices = Object.keys(prev);
  const nextSlices = Object.keys(next);
  if (prevSlices.length !== nextSlices.length) {
    return false;
  }
  if (prevSlices.some(slice => !isEqual(prev[slice], next[slice]))) {
    return false;
  }
  return true;
};

/**
 * Interface for strategies implementing way of initialization
 * of the state.
 */
class InitializationStrategy {}
/**
 * Strategy initializing state after whole angular application is initialized.
 */
class AfterAppInit {
  #initialized = new ReplaySubject(1);
  when() {
    return this.#initialized.pipe(first());
  }
  /**
   * Mark strategy as initialized. Meant to be called once whole angular
   * application is initialized.
   */
  markAsInitialized() {
    this.#initialized.next();
  }
  /**
   * Cleanup resources.
   */
  ngOnDestroy() {
    this.#initialized.complete();
  }
  static {
    this.ɵfac = function AfterAppInit_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AfterAppInit)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AfterAppInit,
      factory: AfterAppInit.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AfterAppInit, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const afterAppInitProvider = {
  provide: APP_BOOTSTRAP_LISTENER,
  multi: true,
  useFactory: (afterInit = inject(AfterAppInit)) => () => {
    afterInit.markAsInitialized();
  }
};
/**
 * Strategy initializing state as soon as it possible, before angular
 * application is initialized.
 */
class BeforeAppInit {
  when() {
    return of(void 0);
  }
  static {
    this.ɵfac = function BeforeAppInit_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || BeforeAppInit)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BeforeAppInit,
      factory: BeforeAppInit.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BeforeAppInit, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AfterAppInit, BeforeAppInit, InitializationStrategy, afterAppInitProvider, createMergeReducer, excludeKeys, includeKeys, isEqual };
