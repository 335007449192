import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, NgModule, makeEnvironmentProviders, inject, ENVIRONMENT_INITIALIZER, Optional, SkipSelf } from '@angular/core';
import * as i3 from '@ngrx-addons/common';
import { isEqual, createMergeReducer, afterAppInitProvider, BeforeAppInit } from '@ngrx-addons/common';
import * as i1 from '@ngrx/store';
import { createAction, props, META_REDUCERS } from '@ngrx/store';
import { Subject, of, merge, fromEvent, tap, map, distinctUntilChanged, skip, finalize, takeUntil, filter, switchMap } from 'rxjs';
const SYNC = '@ngrx-addons/sync-state/sync';
// eslint-disable-next-line @ngrx/prefer-inline-action-props
const storeSyncAction = createAction(SYNC, props());
class SyncStateRootConfig {}
class SyncStateFeatureConfig {}
/**
 * Injection token for the strategy used to initialize the state.
 */
const SyncStateStrategy = new InjectionToken('sync-state-init-strategy');
/**
 * Injection token for the list of states to sync.
 */
const SYNC_STATE_FEATURE_CONFIGS = new InjectionToken('sync-state-feature-configs');
const ROOT_SYNC_STORE = new InjectionToken('sync-state-root');
const FEATURE_SYNC_STATE = new InjectionToken('sync-state-feature');
const rootState = 'root';
class SyncState {
  #rootConfig;
  #features = new Map();
  #destroyer = new Subject();
  constructor(store, strategy, rootConfig) {
    this.store = store;
    this.strategy = strategy;
    const {
      states,
      channelPrefix,
      ...restConfig
    } = rootConfig;
    const prefix = channelPrefix ? `${channelPrefix}-` : '';
    this.#rootConfig = {
      ...restConfig,
      channelPrefix: prefix,
      states
    };
  }
  addRoot() {
    const merged = this.#rootConfig.states?.map(state => ({
      ...this.defaultStateConfig(state.key),
      ...state,
      key: state.key
    })) ?? [];
    this.listenOnStates(merged, rootState).subscribe();
  }
  addFeature(feature) {
    if (this.#features.has(feature.key)) {
      return;
    }
    // Remove in case of re-adding
    this.removeFeature(feature.key);
    this.#features.set(feature.key, true);
    const merged = feature.states.map(state => ({
      ...this.defaultStateConfig(feature.key),
      ...state,
      key: feature.key
    }));
    this.listenOnStates(merged, feature.key).subscribe();
  }
  removeFeature(key) {
    this.#destroyer.next(key);
    this.#features.delete(key);
  }
  ngOnDestroy() {
    this.#features.forEach((_, key) => {
      this.removeFeature(key);
    });
    this.#destroyer.next(rootState);
    this.#destroyer.complete();
  }
  defaultStateConfig(key) {
    return {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      channel: `${this.#rootConfig.channelPrefix}${key}@store`,
      source: state => state,
      runGuard: () => typeof window.BroadcastChannel !== 'undefined',
      skip: 1
    };
  }
  listenOnStates(states, feature) {
    if (states.length === 0) {
      return of(undefined);
    }
    return merge(...states.map(state => {
      if (!state.runGuard()) {
        return of(undefined);
      }
      const stateChannel = new BroadcastChannel(state.channel);
      let canBePosted = true;
      return merge(
      // Sync state from another tab
      this.syncWhen(() => fromEvent(stateChannel, 'message')).pipe(tap(({
        data
      }) => {
        canBePosted = false;
        this.store.dispatch(storeSyncAction({
          features: {
            [state.key]: data
          }
        }));
      })),
      // Sync state to another tab
      state.source(this.store.pipe(map(storeState => storeState[state.key]))).pipe(distinctUntilChanged(isEqual), skip(state.skip), tap(value => {
        if (canBePosted) {
          stateChannel.postMessage(value);
        } else {
          canBePosted = true;
        }
      }), finalize(() => {
        stateChannel.close();
      })));
    })).pipe(takeUntil(this.#destroyer.pipe(filter(destroyFeature => destroyFeature === feature))));
  }
  syncWhen(input) {
    return this.strategy.when().pipe(switchMap(() => input()));
  }
  static {
    this.ɵfac = function SyncState_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SyncState)(i0.ɵɵinject(i1.Store), i0.ɵɵinject(SyncStateStrategy), i0.ɵɵinject(SyncStateRootConfig));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SyncState,
      factory: SyncState.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SyncState, [{
    type: Injectable
  }], () => [{
    type: i1.Store
  }, {
    type: i3.InitializationStrategy,
    decorators: [{
      type: Inject,
      args: [SyncStateStrategy]
    }]
  }, {
    type: SyncStateRootConfig
  }], null);
})();
class SyncStateFeature {
  constructor(syncState, configs) {
    this.syncState = syncState;
    this.configs = configs;
  }
  addFeatures() {
    this.configs.forEach(config => {
      this.syncState.addFeature(config);
    });
  }
  ngOnDestroy() {
    this.configs.forEach(config => {
      this.syncState.removeFeature(config.key);
    });
  }
  static {
    this.ɵfac = function SyncStateFeature_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SyncStateFeature)(i0.ɵɵinject(SyncState), i0.ɵɵinject(SYNC_STATE_FEATURE_CONFIGS));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SyncStateFeature,
      factory: SyncStateFeature.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SyncStateFeature, [{
    type: Injectable
  }], () => [{
    type: SyncState
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [SYNC_STATE_FEATURE_CONFIGS]
    }]
  }], null);
})();
class SyncStateFeatureModule {
  constructor(state) {
    state.addFeatures();
  }
  static {
    this.ɵfac = function SyncStateFeatureModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SyncStateFeatureModule)(i0.ɵɵinject(SyncStateFeature));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SyncStateFeatureModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SyncStateFeatureModule, [{
    type: NgModule
  }], () => [{
    type: SyncStateFeature
  }], null);
})();
const isSyncAction = action => action.type === SYNC;
const syncStateReducer = createMergeReducer(isSyncAction);
const _provideSyncStore = (config = {}) => {
  return [SyncState, {
    provide: SyncStateRootConfig,
    useValue: config
  }, {
    provide: META_REDUCERS,
    useValue: syncStateReducer,
    multi: true
  }, afterAppInitProvider, {
    provide: SyncStateStrategy,
    useExisting: config.strategy ?? BeforeAppInit
  }];
};
const _provideSyncState = config => {
  return [{
    provide: SYNC_STATE_FEATURE_CONFIGS,
    useValue: config,
    multi: true
  }, SyncStateFeature];
};
/**
 * Syncs the global store.
 * These providers cannot be used at the component level.
 *
 * @usageNotes
 *
 * ### Syncing the global store
 *
 * ```ts
 * bootstrapApplication(AppComponent, {
 *   providers: [provideStore(), provideSyncStore()],
 * });
 * ```
 */
const provideSyncStore = (config = {}) => {
  return makeEnvironmentProviders([..._provideSyncStore(config), {
    provide: ROOT_SYNC_STORE,
    useFactory: () => {
      inject(SyncState).addRoot();
    }
  }, {
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useFactory: () => () => inject(ROOT_SYNC_STORE)
  }]);
};
/**
 * Syncs additional slices of state.
 * These providers cannot be used at the component level.
 *
 * @usageNotes
 *
 * ### Sync Store Features
 *
 * ```ts
 * const booksRoutes: Route[] = [
 *   {
 *     path: '',
 *     providers: [provideState('books', booksReducer), provideSyncState({ key: 'books' })],
 *     children: [
 *       { path: '', component: BookListComponent },
 *       { path: ':id', component: BookDetailsComponent },
 *     ],
 *   },
 * ];
 * ```
 */
const provideSyncState = config => {
  return makeEnvironmentProviders([..._provideSyncState(config), {
    provide: FEATURE_SYNC_STATE,
    useFactory: () => {
      inject(SyncStateFeature).addFeatures();
    }
  }, {
    provide: ENVIRONMENT_INITIALIZER,
    multi: true,
    useFactory: () => () => inject(FEATURE_SYNC_STATE)
  }]);
};
class SyncStateRootModule {
  constructor(state, parentModule) {
    if (parentModule) {
      throw new Error('SyncStateRootModule is already loaded. Import it only once at AppModule!');
    }
    state.addRoot();
  }
  static {
    this.ɵfac = function SyncStateRootModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SyncStateRootModule)(i0.ɵɵinject(SyncState), i0.ɵɵinject(SyncStateRootModule, 12));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SyncStateRootModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SyncStateRootModule, [{
    type: NgModule
  }], () => [{
    type: SyncState
  }, {
    type: SyncStateRootModule,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }], null);
})();
class SyncStateModule {
  static forRoot(config = {}) {
    return {
      ngModule: SyncStateRootModule,
      providers: [..._provideSyncStore(config)]
    };
  }
  static forFeature(config) {
    return {
      ngModule: SyncStateFeatureModule,
      providers: [..._provideSyncState(config)]
    };
  }
  static {
    this.ɵfac = function SyncStateModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SyncStateModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SyncStateModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SyncStateModule, [{
    type: NgModule
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { SYNC, SyncState, SyncStateFeatureConfig, SyncStateFeatureModule, SyncStateModule, SyncStateRootConfig, SyncStateRootModule, provideSyncState, provideSyncStore, storeSyncAction };
